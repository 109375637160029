export default {
    namespaced: true,
    state: {
        filterSelectedData: {},
        withoutPricesStepCount: 0,
        withoutPricesSideCount: 0,
        isFilterLoading: false,
        isFilterUpdated: false,
        cityList: []
    },
    getters: {
        filterSelectedData(state) {
            return state.filterSelectedData;
        },
        currentCity(state) {
            const {city_id: cities = []} = state.filterSelectedData;
            const cityId = cities[0];
            const indexCity = state.cityList.findIndex(city => String(city.id) === String(cityId));
            return state.cityList[indexCity] ?? {};
        }
    },
    mutations: {
        setCityList(state, list){
            state.cityList = list;
        },
        setFilterSelectedData(state, filterSelectedData) {
            state.filterSelectedData = filterSelectedData;
        },
        setWithoutPricesStepCount(state, withoutPricesStepCount) {
            state.withoutPricesStepCount = withoutPricesStepCount;
        },
        setWithoutPricesSideCount(state, withoutPricesSideCount) {
            state.withoutPricesSideCount = withoutPricesSideCount;
        },
        setIsFilterLoading(state, isFilterLoading) {
            state.isFilterLoading = isFilterLoading;
        },
        setIsFilterUpdated(state, isFilterUpdated) {
            state.isFilterUpdated = isFilterUpdated;
        },
        setCircleRadius(state, radius) {
            state.circle_radius = radius;
        },
        setCircePoints(state, circlePoints) {
            state.circe_points = circlePoints;
        },
    },
}
