<template>
    <div class="layout">
        <div class="layout__main" :style="{overflow: layoutOverflow}">
            <Cap
                class="layout__cap"
                :title="$route.meta.title || 'Oohdesk'"
                :logo-src="capLogoSrc"
                view="transparent"
                :config="capConfig"
            />
            <div
                class="layout__content"
            >
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
// import ServiceApi from '../services/ServiceApi';
export default {
    name: 'LayoutBaseWithCap',
    // components: {
    //     AppFilter,
    //     FilterPanel,
    // },
    data() {
        return {

        }
    },

    mounted() {

    },

    computed: {
        layoutOverflow() {
            // if (RECONCILIATION_PAGES.includes(this.currentRoute)) {
                return 'auto'
            // }
            // return 'hidden'
        },

        capConfig() {
            return {
                isConfigHamburger: false,
                isConfigStatus: false,
                isConfigNotification: false,
                isConfigSettings: false,
                isConfigUserMenu: false
            };
        },

        capLogoSrc() {
            // return require('@/assets/images/logo-sv-min.svg');
            return require('@/assets/images/OohdeskBUYER.svg');
        }
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.layout {
    height: 100vh;
    display: flex;

    .loading {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    &__main {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    &__content {
        position: relative;
        // z-index: 100;
        width: 100%;
        height: 100%;
        background: #fff;
        // overflow: hidden;
        padding: 10px 40px 40px 40px;
    }


    &__filter {
        padding: 20px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 3;
    }
}
</style>
