import Vue from 'vue';
import axios from 'axios';
import VTooltip from 'v-tooltip';
import Clipboard from 'v-clipboard';
import vClickOutside from 'v-click-outside';
import DNotification from "@/plugins/DNotification";

import {pluginCap as Cap} from '@oohdesk-kit/lince-cap';
import {
    pluginWidgetMenu as WidgetMenu,
    pluginWidgetMapPoi as WidgetMapPoi,
    pluginColorPanel as ColorPanel,
    pluginAttachFile as AttachFile
} from '@oohdesk-kit/lince-widgets-map';

import LinceMapWrapper from '@oohdesk-kit/lince-map';
import LinceRealtimeSystem from '@oohdesk-kit/lince-realtime-system';

import App from './App.vue';
import store from './store';
import router from './router';
import "./assets/scss/main.scss";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.use(DNotification);
Vue.use(VTooltip);
Vue.use(Clipboard);
Vue.use(vClickOutside);
Vue.use(Cap);
Vue.use(LinceMapWrapper);
Vue.use(LinceRealtimeSystem);
Vue.use(WidgetMenu);
Vue.use(WidgetMapPoi);
Vue.use(ColorPanel);
Vue.use(AttachFile);

window.vueRouter = router;
new Vue({
	render: h => h(App),
	store,
	router,
}).$mount('#app');
